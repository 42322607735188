import React from 'react'

const Home = () => {
  return (
    <header>
      <h1 className="title">Emotional Fitness</h1> 
      <h3 className="sub-title" >Psychology Services</h3> 
      <h2>Coming soon</h2>
      <p className="description" >
        <i>Emotional fitness for life - work and ageing well.</i>
      </p>

      <p className="description" >
      Emotional fitness is not something that you have or do not have. It is the capacity to bounce back quickly and adaptively from difficult times and situations. It also helps to set the foundation for building creativity, courage, personal strength, grit, focus and authentic engagement in the world and with others. It involves intentions, behaviours, thoughts, actions and greater awareness and connection with yourself and it can be trained!
      </p>
      <p className="description" >
      Emotional fitness psychology services will provide ways for you to recognise key factors that influence, sustain and improve your own emotional fitness and help you train for peak cognitive and emotional states.
      </p>
      <p className="description" >
      Let me know what emotional fitness means for you and what you would like to build and learn more about: 
      </p>

      <p>What are you interested in?</p>
      

      <p className="description" ><i>
      For more information contact Michelle at  <br/>
        <a href="mailto:efitnesspsychology@gmail.com?Subject=website%20enquiry">efitnesspsychology@gmail.com</a>
      </i></p>
    </header>
  )
}

export default Home