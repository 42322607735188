import React, { Component } from 'react';
import './App.scss';

import Home from './components/Home'
import Checkboxes from './components/Checkboxes'

class App extends Component {
  render() {
    return (
      <div className="App header" >
        <Home/>
      </div>
    );
  }
}

export default App;
